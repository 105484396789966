<template>
  <div class="inventory-item--adjust text__center">
    <span class="text__white mr-10">Adjustment</span>
  </div>
</template>

<script>
export default {
  props: ["quantity"]
};
</script>

<style scoped lang="scss">
.inventory-item--adjust {
  @include pos-abs(
    $pos: absolute,
    $top: null,
    $left: -5px,
    $bottom: -5px,
    $width: 150px
  );
  padding: 5px 10px;
  background: linear-gradient(135deg, $maroon, $black);
  margin: 0 !important;
  clip-path: polygon(0% 0%, 82% 0, 100% 100%, 0% 100%);
}
</style> 