<template>
  <div class="inventory-item tile pos__rel">
    <CreatedDate :date="cleanDate" />
    <Edit @toggleModal="toggleModal" />
    <!-- <span class="text__bold text__center">{{ cleanDate }}</span> -->
    <span class="text__bold text__center inventory-item--product">{{ item.productName }}</span>
    <label>
      Quantity
      <span class="text__center">{{ item.quantity }}</span>
    </label>
    <label>
      Current Inv.
      <span class="text__center">{{ item.currentInv }}</span>
    </label>
    <label>
      Item Cost
      <span class="text__center">${{ item.itemCost.toFixed(2) }}</span>
    </label>
    <label>
      Invoice Cost
      <span class="text__center">${{ item.invoiceCost.toFixed(2) }}</span>
    </label>
    <label>
      Receipt
      <span v-if="!item.receipt" class="text__center">No Receipt</span>
      <a v-else :href="item.receipt" target="_blank" class="text__center">View Receipt</a>
    </label>
    <label id="label--comments">
      Comments
      <span>{{ shortComments }}</span>
    </label>
    <Adjust v-if="item.adjustment" />
    <Modal v-if="modalToggled" @toggleModal="toggleModal" :item="item" />
  </div>
</template>

<script>
import moment from "moment";
import CreatedDate from "./CreatedDate";
import Edit from "./Edit";
import Adjust from "./Adjust";
import Modal from "./Modal";
export default {
  props: ["item"],
  components: {
    CreatedDate,
    Edit,
    Adjust,
    Modal
  },
  data() {
    return {
      modalToggled: false
    };
  },
  computed: {
    cleanDate() {
      return moment(this.item.createdAt).format("M-D-YYYY hh:mma");
    },
    shortComments() {
      return `${this.item.comments.slice(0, 125)}${
        this.item.comments.length > 125 ? "..." : ""
      }`;
    }
  },
  methods: {
    toggleModal() {
      this.modalToggled = !this.modalToggled;
    }
  }
};
</script>

<style scoped lang="scss">
.inventory-item {
  @include grid($cols: 1fr, $col-gap: 10px, $row-gap: 10px);
  height: 100%;
  label {
    @include grid($cols: 1fr 1fr, $col-gap: 10px, $align: center);
    font-weight: bold;
    span {
      font-weight: normal;
      text-align: right;
    }
  }
}
.inventory-item--product {
  margin-top: 15px;
}
#label--comments {
  @include grid($cols: 1fr, $row-gap: 5px);
  height: fit-content;
  margin-bottom: 15px;
  span {
    text-align: left;
    height: 100px;
  }
}
</style>