<template>
  <div class="inventory-item--date text__center">
    <span class="text__white">{{ date }}</span>
  </div>
</template>

<script>
export default {
  props: ["date"]
};
</script>

<style scoped lang="scss">
.inventory-item--date {
  @include pos-abs($pos: absolute, $top: -5px, $left: -5px, $width: 200px);
  padding: 5px 10px;
  background: linear-gradient(135deg, $black, $green);
  margin: 0 !important;
  clip-path: polygon(0% 0%, 100% 1%, 83% 100%, 0% 100%);
}
span {
  margin-right: 25px;
}
</style> 