<template>
  <div class="inventory-item--edit flex__center pointer" @click="emitToggleModal">
    <font-awesome-icon icon="edit" size="sm" class="mr-10" />View
  </div>
</template>

<script>
export default {
  methods: {
    emitToggleModal() {
      this.$emit("toggleModal");
    }
  }
};
</script>

<style scoped lang="scss">
.inventory-item--edit {
  @include pos-abs($pos: absolute, $top: 0px, $left: null, $right: 0px);
  margin: 0 !important;
  padding: 3px 5px;
  color: $green;
}
</style> 