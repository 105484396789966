<template>
  <div class="modal flex__center" @click.self="emitToggleModal">
    <section class="tile">
      <simplebar class="tile--overflow" data-simplebar-auto-hide="false">
        <section class="inventory-item">
          <span class="text__bold text__center">{{ cleanDate }}</span>
          <span class="text__bold text__center">$10 Target Gift Card</span>
          <label>
            Quantity
            <span class="text__center">{{ item.quantity }}</span>
          </label>
          <label :class="[errors.itemCost ? 'form--error' : null]">
            Item Cost
            <span v-if="!editing" class="text__center">${{ item.itemCost.toFixed(2) }}</span>
            <input v-else type="number" min="0" v-model="form.itemCost" />
          </label>
          <form-error v-if="editing" :message="errors.itemCost" />
          <label :class="[errors.invoiceCost ? 'form--error' : null]">
            Invoice Cost
            <span
              v-if="!editing"
              class="text__center"
            >${{ item.invoiceCost.toFixed(2) }}</span>
            <input v-else type="number" min="0" v-model="form.invoiceCost" />
          </label>
          <form-error v-if="editing" :message="errors.invoiceCost" />
          <label v-if="editing">
            Upload Receipt
            <span class="text__sm">
              <a
                :href="`https://techsupportflatbranch.sharepoint.com/teams/WOWDesk/Shared%20Documents/Forms/AllItems.aspx?viewid=a678bc10%2D6d70%2D4237%2Dbd50%2D3836adee4639&id=%2Fteams%2FWOWDesk%2FShared%20Documents%2Fwow%2Dinventory`"
                target="_blank"
              >Sharepoint / WOW Desk / wow-inventory</a>
            </span>
          </label>
          <label>
            Receipt
            <span v-if="!editing && !item.receipt" class="text__center">No Receipt</span>
            <a
              v-if="!editing && item.receipt"
              :href="item.receipt"
              target="_blank"
              class="text__center"
            >View Receipt</a>
            <input v-if="editing" type="text" v-model="form.receipt" placeholder="receipt link" />
          </label>
          <label id="label--comments">
            Comments
            <span v-if="!editing">{{ item.comments }}</span>
            <textarea v-else v-model="form.comments" rows="3" />
          </label>
          <div v-if="!editing" class="flex__between">
            <button class="btn btn__sm btn__red" @click="emitToggleModal">Exit</button>
            <button class="btn btn__sm btn__frost flex__center" @click="toggleEditing">
              <font-awesome-icon icon="edit" size="sm" class="mr-10" />Edit
            </button>
          </div>
          <div v-if="editing" class="flex__between">
            <button class="btn btn__sm btn__red" @click="toggleEditing">Cancel</button>
            <button class="btn btn__sm btn__green" @click="submit">Done</button>
          </div>
        </section>
      </simplebar>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["item"],
  data() {
    return {
      editing: false,
      isValidated: true,
      form: {
        _id: this.item ? this.item._id : null,
        itemCost: this.item ? this.item.itemCost : 0,
        invoiceCost: this.item ? this.item.invoiceCost : 0,
        receipt: this.item ? this.item.receipt : null,
        comments: this.item ? this.item.comments : ""
      },
      errors: {
        itemCost: null,
        invoiceCost: null
      }
    };
  },
  computed: {
    cleanDate() {
      return moment(this.item.createdAt).format("MM-DD-YYYY hh:mma");
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateInventory"]),
    emitToggleModal() {
      this.$emit("toggleModal");
    },
    toggleEditing() {
      this.editing = !this.editing;
    },
    async submit() {
      this.startSetIsSubmitting({ bool: true, heading: "Updating" });
      try {
        await this.startUpdateInventory(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validateForm() {
      this.isValidated = true;
      this.errors.itemCost =
        isNaN(parseFloat(this.form.itemCost)) ||
        parseFloat(this.form.itemCost) <= 0
          ? "Please enter a number greater than 0"
          : null;
      this.errors.invoiceCost =
        isNaN(parseFloat(this.form.invoiceCost)) ||
        parseFloat(this.form.invoiceCost) <= 0
          ? "Please enter a number greater than 0"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tile {
  width: 90%;
  max-width: 400px;
}
.tile--overflow {
  max-height: 375px;
}
.inventory-item {
  padding-right: 15px;
  @include grid($cols: 1fr, $col-gap: 10px, $row-gap: 5px);
  label {
    @include grid($cols: 1fr 1fr, $col-gap: 10px, $align: center);
    height: 45px;
    font-weight: bold;
    span {
      font-weight: normal;
      text-align: right;
    }
  }
}
#label--comments {
  @include grid($cols: 1fr, $row-gap: 5px);
  height: fit-content;
  span {
    text-align: left;
  }
}
</style>